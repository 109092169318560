import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import {
  UiLoadingElementSizeType,
  UiLoadingElementThemeType,
} from '@common/types';

@Component({
  selector: 'app-loader-element',
  templateUrl: './loader-element.component.svg',
  styleUrls: ['./loader-element.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderElementComponent {
  @Input()
  public size: UiLoadingElementSizeType = 'large';

  @Input()
  public theme: UiLoadingElementThemeType = 'white';

  @HostBinding('class')
  public get classValue(): string {
    return `${this.size} ${this.theme}`;
  }
}
