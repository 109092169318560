import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  forwardRef,
  HostBinding,
  inject,
  Input,
  OnInit,
  signal,
} from '@angular/core';
import {
  AbstractControl,
  DefaultValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';
import { KeyValue, NgClass, NgIf } from '@angular/common';
import { UiTextControlThemeType } from '@common/types';
import { LibIconComponent } from '@common/components/lib-icon';
import { appIcons } from '../../../../assets/app-icon.model';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'ui-text-control',
  templateUrl: './ui-text-control.component.html',
  styleUrls: ['./ui-text-control.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule, NgIf, NgClass, LibIconComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiTextControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => UiTextControlComponent),
      multi: true,
    },
  ],
})
export class UiTextControlComponent
  extends DefaultValueAccessor
  implements OnInit, Validator
{
  private readonly destroyRef = inject(DestroyRef);
  public readonly control = new FormControl<string | null>(null);

  public readonly errors = signal<Record<string, any> | null>(null);
  private readonly rawClassList = signal<KeyValue<string, string>[]>([
    { key: 'theme', value: 'outline' },
  ]);
  private readonly classList = computed(() => {
    const classList = this.rawClassList();
    const errors = this.errors();

    const list = cloneDeep(classList);
    if (errors && Object.keys(errors).length > 0 && this.control.touched) {
      list.push({ key: 'errors', value: 'has-errors' });
    }
    return list;
  });
  private readonly classCollection = computed(() => {
    const classList = this.classList();

    return new Map<string, KeyValue<string, string>>(
      classList.map(({ key, value }) => [key, { key, value }]),
    );
  });
  public readonly classValue = computed(() => {
    const classList = this.classList();
    return classList.map(({ value }) => value).join(' ');
  });

  @Input()
  public label?: string;

  @Input()
  public required?: boolean;

  @Input()
  public placeholder: string = '';

  @Input()
  public iconName?: appIcons;

  @Input()
  public set theme(val: UiTextControlThemeType) {
    const collection = this.classCollection();
    const themeData = collection.get('theme');
    if (themeData) {
      themeData.value = val;
      this.rawClassList.set(
        Array.from(collection).map(([_key, value]) => value),
      );
    }
  }

  @Input()
  public positionReverse: boolean = false;

  @Input()
  public readOnly: boolean = false;

  @HostBinding('class')
  public get hostClassValue(): string {
    const withLabel = !!this.label ? 'with-label' : '';
    return `${withLabel}`.trim();
  }

  public ngOnInit(): void {
    this.control.valueChanges
      .pipe(
        tap((val: string | null) => this.onChange(val)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  public override writeValue(value: string | null): void {
    this.control.patchValue(value, { emitEvent: false });
  }

  public override setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    this.errors.set(control.errors);
    return null;
  }
}
