import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  DestroyRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  inject,
  Input,
  Output,
  signal,
} from '@angular/core';
import { InputUtilsDirective } from '@common/directives';
import { map, merge, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgIf } from '@angular/common';
import { LibButtonComponent } from '../lib-button';

/**
 * @deprecated
 */
@Component({
  selector: 'app-input-container',
  templateUrl: './input-container.component.html',
  styleUrls: ['./input-container.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [forwardRef(() => LibButtonComponent), NgIf],
})
export class InputContainerComponent implements AfterContentInit {
  private readonly destroyRef = inject(DestroyRef);
  public labelActive = signal(false);

  @ContentChild(InputUtilsDirective)
  public inputField?: InputUtilsDirective<string>;

  @HostBinding('class.focused')
  public isFocused: boolean = false;

  @HostBinding('class.error')
  @Input()
  public error: boolean = false;

  @Input()
  public label?: string;

  @Input()
  public submitButton: boolean = false;

  @Input()
  public submitDisabled: boolean = false;

  @Input()
  public submitLoading: boolean = false;

  @Input()
  public submitLabel?: string;

  @HostBinding('class')
  @Input()
  public theme?: string;

  @Output()
  public readonly submitEvent = new EventEmitter<void>();

  @HostListener('click', ['$event'])
  public clickEvent($event: MouseEvent): void {
    $event.stopPropagation();
    this.inputField?.click();
  }

  public ngAfterContentInit() {
    if (!this.inputField) {
      return;
    }
    merge(
      this.inputField.focusEvent.pipe(map(() => true)),
      this.inputField.blurEvent.pipe(map(() => false)),
    )
      .pipe(
        tap((status: boolean) => {
          this.isFocused = status;
          this.checkLabel();
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  private checkLabel(): void {
    const value: string | undefined = this.inputField?.getValue();
    this.labelActive.set(this.isFocused || !!value);
  }

  public submit(): void {
    this.submitEvent.emit();
  }
}
