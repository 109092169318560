<div class="flex gap-12" [class.flex-row-reverse]="positionReverse">
  <lib-icon
    *ngIf="iconName"
    [name]="iconName"
    class="button-icon flex"
  ></lib-icon>
  <p *ngIf="text" class="text-14 leading-20 m-0 button-text inter-600">
    {{ text }}
  </p>
</div>
<div
  class="loading-container absolute left-0 right-0 bottom-0 top-0 flex"
  *ngIf="loading"
>
  <app-loader-element
    [size]="'small'"
    [theme]="loadingTheme"
  ></app-loader-element>
</div>
