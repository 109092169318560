import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { LibIconComponent } from '@common/components';

/**
 * @deprecated
 */
@Component({
  selector: 'lib-button',
  templateUrl: './lib-button.component.html',
  styleUrls: ['./lib-button.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, LibIconComponent, NgClass],
})
export class LibButtonComponent {
  @Input()
  public label?: string;

  @HostBinding('class.disabled')
  @Input()
  public disabled: boolean = false;

  @HostBinding('class.loading')
  @Input()
  public loading: boolean = false;

  @HostBinding('class')
  @Input()
  public theme?: string;

  @Output()
  public readonly onClick = new EventEmitter<MouseEvent>();

  @HostListener('click', ['$event'])
  private clickEvent($event: MouseEvent): void {
    $event.stopPropagation();
    if (this.disabled || this.loading) {
      $event.preventDefault();
      return;
    }
    this.onClick.emit($event);
  }
}
