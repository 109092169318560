import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { debounceTime, delay, map, Observable, of } from 'rxjs';
import { convertUtil } from '@common/utils';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  private readonly http = inject(HttpClient);

  public login(body: any): Observable<any> {
    return this.http
      .post('/auth/v1/login/email', body)
      .pipe(map((data: any) => convertUtil(data)));
  }

  public loginConfirmation(body: any): Observable<any> {
    return this.http
      .post('/auth/v1/login/code', convertUtil(body, 'snake_case'))
      .pipe(map((res) => convertUtil(res)));
  }

  public resendCode(body: any): Observable<any> {
    return this.http
      .post('/auth/v1/login/code/send-new', convertUtil(body, 'snake_case'))
      .pipe(map((res) => convertUtil(res)));
  }

  public signUp(body: any): Observable<any> {
    return this.http
      .post('/auth/v1/signup', convertUtil(body, 'snake_case'))
      .pipe(map((res) => convertUtil(res)));
  }

  public signUpConfirmation(body: any) {
    return this.http
      .post('/auth/v1/signup/code', convertUtil(body, 'snake_case'))
      .pipe(map((res) => convertUtil(res)));
  }
}
