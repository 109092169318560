import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import { LoaderElementComponent } from '../loader-element';
import { UiLoadingElementThemeType } from '@common/types';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LoaderElementComponent],
})
export class LoaderComponent {
  @Input()
  public theme: UiLoadingElementThemeType = 'white';
}
