import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { LibIconComponent } from '../lib-icon';
import { NgIf } from '@angular/common';
import { appIcons } from '../../../../assets/app-icon.model';
import { LoaderElementComponent } from '@common/components/loader-element';
import {
  UiButtonSizeType,
  UiButtonThemeType,
  UiLoadingElementThemeType,
} from '@common/types';

@Component({
  selector: 'ui-button',
  templateUrl: './ui-button.component.html',
  styleUrls: ['./ui-button.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LibIconComponent, NgIf, LoaderElementComponent],
})
export class UiButtonComponent {
  @Input()
  public theme: UiButtonThemeType = 'purple';

  @Input()
  public size: UiButtonSizeType = 'large';

  @HostBinding('class.disabled')
  @Input()
  public disabled?: boolean;

  @Input()
  public loading: boolean = false;

  @Input()
  text?: string;

  @Input()
  iconName?: appIcons;

  @Input()
  positionReverse: boolean = false;

  @HostBinding('class')
  public get classValue(): string {
    return `${this.theme} ${this.size} ${this.loading ? 'loading' : ''}`.trim();
  }

  public get loadingTheme(): UiLoadingElementThemeType {
    if (this.theme === 'purple') {
      return 'white';
    }
    if (this.theme === 'light-purple') {
      return 'purple';
    }
    return 'white';
  }

  @Output()
  public readonly onClick = new EventEmitter<MouseEvent>();

  @HostListener('click', ['$event'])
  public clickEvent($event: MouseEvent): void {
    $event.stopPropagation();
    if (this.disabled || this.loading) {
      $event.preventDefault();
      return;
    }
    this.onClick.emit($event);
  }
}
