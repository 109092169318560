<div class="flex" *ngIf="label">
  <label class="text-14 leading-20 inter-600"
    >{{ label }}
    <span *ngIf="required" class="ui-color-red-500">&nbsp;*</span>
  </label>
</div>
<div
  class="input-wrapper flex flex-1 w-full relative"
  [ngClass]="classValue()"
  [class.gap-8]="iconName"
>
  <input
    type="text"
    [formControl]="control"
    class="text-14 flex-1 inter-400 leading-20"
    [class.!pl-44]="iconName && !positionReverse"
    [class.!pr-44]="iconName && positionReverse"
    [class.filled]="control.value"
    [placeholder]="placeholder"
    autocomplete="off"
    spellcheck="false"
    autocorrect="false"
    [readOnly]="readOnly"
  />
  <lib-icon
    [name]="iconName"
    *ngIf="iconName"
    class="text-control-icon w-20 shrink-0 flex absolute m-auto top-0 bottom-0"
    [class.left-16]="!positionReverse"
    [class.right-16]="positionReverse"
  ></lib-icon>
</div>
